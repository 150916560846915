<template>
  <div
    fluid
    style="height: 100%; width: 100%"
  >
    <v-tabs height="56" v-model="tab" style="width: 100%">
      <v-tab
        v-for="(item, index) in menuItems"
        :key="index"
        :disabled="item.disabled"
        :color="!item.disabled ? '#ff9800' : '#5d5d5d'"
        :to="item.link"
      >
        <v-icon v-if="item.icon" class="mr-2">{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <router-view/>
</div>
</template>

<script>
import { mapState } from "pinia"
import { useAuthStore } from "./../store/index"

export default {
  data () {
    return {
      menuItems: [
        { title: 'Vertices', icon: 'mdi-chart-line', link: '/'},
        { title: 'Curves', icon: 'mdi-chart-bell-curve', link: '/curves'},
      ],
      tab: undefined
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(useAuthStore, [
      "isAuthenticated"
    ])
  },
  methods: {
  }
}
</script>