<template>
  <div style="width: 100%; height: 100%">
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          width="100%"
          class="py-7 px-8"
          style="background-color: #121212"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="d-flex flex-column align-center"
            >
              <v-card
                outlined
                v-for="(item, rowIndex) in tableItems"
                :key="rowIndex"
                class="d-flex mt-7 mb-5 flex-column align-center"
                style="background-color: #121212; min-width: 950px;"
              >
                <v-card-title class="text-h5 font-weight-bold mb-n7">
                  {{ item.name }}
                </v-card-title>
                <div class="d-flex justify-center">
                  <div>
                    <v-chart
                      :option="getChartOptions(item)"
                      class="chart"
                      autoresize
                    ></v-chart>
                  </div>

                  <v-simple-table class="mt-9">
                    <thead>
                      <tr>
                        <th class="text-subtitle-1 font-weight-bold">Ticker</th>
                        <th class="text-subtitle-1 font-weight-bold">Bid</th>
                        <th class="text-subtitle-1 font-weight-bold">Ask</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(itemTable, rowIndex) in item.items" :key="rowIndex">
                        <td>
                          {{ itemTable.ticker }}
                        </td>

                        <td v-for="(value, colIndex) in itemTable.values" :key="colIndex">
                          {{ value }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-card>
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VChart from "vue-echarts"
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { mapState } from "pinia"
import { useTableStore } from "@/store"

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
])

export default {
  components: {
    VChart,
  },
  data() {
    return {
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(useTableStore, [
      "di1",
      "frc",
    ]),
    tableItems () {
      if (!this.di1 || !this.frc) return undefined
      const items = [this.di1, this.frc]
      return items
    }
  },
  methods: {
    getChartOptions(item) {
      const allValues = item.items.flatMap((i) => i.values);
      const minValue = Math.min(...allValues);
      const maxValue = Math.max(...allValues);
      const range = maxValue - minValue;
      const interval = range > 0.1 ? range / 5 : 0.0001;
      const adjustedMin = Math.floor(minValue / interval) * interval;
      const adjustedMax = Math.ceil(maxValue / interval) * interval;

      return {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Ask", "Bid"],
          textStyle: {
            color: "#fff",
          },
          orient: "horizontal",
          top: "10%",
          left: "center",
          icon: "rect",
          itemWidth: 15,
          itemHeight: 10,
        },
        grid: {
          left: "10%",
          right: "10%",
          top: "15%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: item.items.map((i) => i.ticker),
          axisLabel: { color: "#fff" },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
            formatter: (value) => value.toFixed(4),
          },
          min: adjustedMin,
          max: adjustedMax,
        },
        series: [
          {
            name: "Ask",
            type: "line",
            data: item.items.map((i) => i.values[1]),
            smooth: true,
            itemStyle: { color: "#ff9800" },
          },
          {
            name: "Bid",
            type: "line",
            data: item.items.map((i) => i.values[0]),
            smooth: true,
            itemStyle: { color: "#00a9d4" },
          },
        ],
      };
    }
  },
}
</script>

<style scoped>
.chart {
  max-width: 700px;
  min-width: 700px;
  width: 100%;
  height: 350px
}
</style>
