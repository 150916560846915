<template>
  <v-app v-resize="updateWindowHeight">
    <v-navigation-drawer 
      app
      :value="isDrawerOpened"
      :mini-variant="isMiniMenuActivated && !isMobile"
      :permanent="!isMobile"
      :temporary="isMobile"
      width="190"
      mini-variant-width="60"
      style="z-index: 106;"
      stateless
      color="base"
    >
      <v-list :rounded="!isMiniMenuActivated">
        <v-list-item class="px-2" style="min-height: 61.98px; max-height: 61.98px;">
          <v-list-item-content v-if="!isMiniMenuActivated">
            <v-list-item-title class="font-weight-bold text-h6">Controls</v-list-item-title>
          </v-list-item-content>

          <v-btn
            icon
            color="accent"
            @click="changeMenuState()"
          >
            <v-icon>
              {{ !isMiniMenuActivated ? "mdi-chevron-left" : "mdi-chevron-right" }}
            </v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list>
        <v-list-item class="d-flex flex-column" v-if="!isMiniMenuActivated" style="min-height: 0px">
          <v-list-item-content class="d-flex justify-center font-weight-bold mb-n7">
            <v-list-item-title class="text-center">DI1 (PRE)</v-list-item-title>
          </v-list-item-content>

          <v-radio-group v-model="selectedOptionDI1">
            <v-radio label="No override" value="noOverride" class="disabled" disabled></v-radio>
            <v-radio label="Adjust Px" value="adjustPx" class="disabled" disabled></v-radio>
            <v-radio label="Last Px" value="lastPx" class="disabled" disabled></v-radio>
            <v-radio label="Close Px (D-1)" value="closePx" class="disabled" disabled></v-radio>
            <v-radio label="Interpolate" value="interpolate" class="disabled" disabled></v-radio>
            <v-radio label="Excel Add-in" value="excelAdd" ></v-radio>
          </v-radio-group>
          
          <v-btn
            disabled
            class="mt-n4"
          >
            Submit
          </v-btn>
        </v-list-item>

        <v-list-item class="d-flex flex-column mt-2" v-if="!isMiniMenuActivated" style="min-height: 0px">
          <v-list-item-content class="d-flex justify-center font-weight-bold mb-n7">
            <v-list-item-title class="text-center">FRC (CPN)</v-list-item-title>
          </v-list-item-content>

          <v-radio-group v-model="selectedOptionFRC" >
            <v-radio label="No override" value="noOverride" class="disabled" disabled></v-radio>
            <v-radio label="Adjust Px" value="adjustPx" class="disabled" disabled></v-radio>
            <v-radio label="Last Px" value="lastPx" class="disabled" disabled></v-radio>
            <v-radio label="Close Px (D-1)" value="closePx" class="disabled" disabled></v-radio>
            <v-radio label="Interpolate" value="interpolate" class="disabled" disabled></v-radio>
            <v-radio label="Excel Add-in" value="excelAdd" ></v-radio>
          </v-radio-group>
          
          <v-btn 
            disabled
            class="mt-n4"
          >
            Submit
          </v-btn>
        </v-list-item>

        <v-divider class="mt-2" v-if="!isMiniMenuActivated"></v-divider>

        <v-form v-if="!isMiniMenuActivated" fast-fail @submit.prevent class="mx-auto" style="width: 160px">
          <v-text-field
            v-model="cdi"
            :rules="onlyNumberRules"
            label="CDI"
            @input="handleInput"
          ></v-text-field>
  
          <v-text-field
            v-model="wdof25"
            :rules="onlyNumberRules"
            label="WDOF25"
            @input="handleInput"
          ></v-text-field>
          
          <v-text-field
            v-model="wdog25"
            :rules="onlyNumberRules"
            label="WDOG25"
            @input="handleInput"
          ></v-text-field>

          <v-text-field
            v-model="dr1"
            :rules="onlyNumberRules"
            label="DR1"
            @input="handleInput"
          ></v-text-field>
          <v-btn class="" type="submit" @click="setForms" block>Submit</v-btn>
        </v-form>
      </v-list>

      <v-divider v-if="!isMiniMenuActivated"></v-divider>
      
      <div>
        <div
          class="
            d-flex
            flex-column
            ml-4
            mr-2
          "
          style="font-size: 14px;"
        >
          <div v-if="!isMiniMenuActivated" class="d-flex flex-column mt-2">
            <div class="mb-1">
              <span class="font-weight-bold">Last fetch: </span>
              <span v-if="lastUpdateState"> {{ lastUpdateState | formatTime }} </span>
              <span v-else> {{ now | formatTime }} </span>
            </div>
  
            <div
              class=
                "d-flex
                justify-center
                mb-1
              "
              style="
                color: white;
                background-color: rgba(0, 0, 0, 0.8);
                gap: 5px
              "
            >
              <span>Reload in </span>
              <span> {{ countdown }} </span> secs
            </div>
  
            <div class="d-flex">
              <span class="font-weight-bold">OctaX PRO:</span>
              <span class="sync ml-2"> SYNC </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <template v-slot:append>
        <v-list 
          :rounded="!isMiniMenuActivated"
        >
          <v-subheader v-if="!isMiniMenuActivated">Account</v-subheader>

          <v-list-item>
            <v-icon>
              mdi-account-circle-outline
            </v-icon>
            <span class="ml-8">
              -
            </span>
          </v-list-item>
          
          <v-list-item
            @click="logout()"
            color="accent"
          >
            <v-tooltip bottom v-if="isMiniMenuActivated">
              <template v-slot:activator="{ on }">
                <v-list-item-icon v-on="on">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
              </template>
              <span v-if="isMiniMenuActivated">Logout</span>
            </v-tooltip>

            <v-list-item-icon v-else>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </v-list>
      </template> -->
    </v-navigation-drawer>

    <v-app-bar
      app
      color="base"
      dark
      :elevation="12"
      style="z-index: 105;"
    >
      <v-app-bar-nav-icon 
        v-if="isMobile"
        @click="drawerState = !drawerState"
      >
      </v-app-bar-nav-icon>

      <!-- <div class="ml-1">
        <v-list-item-title>
          <strong>
            <template>
              Curves
            </template>
          </strong>
        </v-list-item-title>
      </div> -->

      <v-spacer></v-spacer>

      <!-- <div>
        <span class="font-weight-bold">Last Update: </span>{{ now | formatTime }}
      </div> -->
    </v-app-bar>

    <v-main style="padding-top: 64px;">
      <main-container/>
    </v-main>

    <v-footer 
      app 
      padless 
      dense 
      height="30"
      color="base"
      style="border-top: 1px solid #ffffff1f !important;"
    >
      <v-row
        no-gutters
        class="d-flex align-center"
      >
      </v-row>
    </v-footer>

    <v-snackbar 
      v-model="snackbarSuccess" 
      :timeout="4000" 
      bottom
      color="alive"
    >
      Dados enviados com sucesso!
      <template v-slot:action="{ attrs }">
        <v-btn 
          text 
          v-bind="attrs"
          @click="snackbarSuccess = false"
        > 
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia"
import { 
  useAuthStore, 
  useDisplayStore,
  useTableStore,
} from "./store/index"
import MainContainer from "./components/MainContainer.vue"

export default {
  name: "App",
  components: {
    MainContainer
  },
  filters: {
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
  },
  watch: {
    breakpoint: {
      handler (newValue) {
        this.setBreakpoint(newValue)
      }
    },
  },
  mounted () {
    this.setBreakpoint(this.breakpoint)
    this.startCountdown()
    this.fetchMinutelyAndUpdate()
  },
  beforeDestroy () {
    clearInterval(this.interval)
    clearInterval(this.countdownInterval)
    clearTimeout(this.editTimeout)
  },
  data () {
    return {
      tab: 0,
      menus: [
        { label: "Hedge", to: "/home" },
      ],
      panel: undefined,
      selectedOptionDI1: "excelAdd",
      selectedOptionFRC: "excelAdd",
      countdown: undefined,
      interval: undefined,
      countdownInterval: undefined,
      editTimeout: undefined,
      cdi: '',
      wdof25: '',
      wdog25: '',
      dr1: '',
      onlyNumberRules: [
        v => !isNaN(v) || 'Only numbers are allowed',
      ],
      snackbarSuccess: false,
      isEditing: false,
    }
  },
  computed: {
    ...mapState(useTableStore, [
      "lastUpdate",
    ]),
    ...mapWritableState(useTableStore, {
      lastUpdateState: "lastUpdate",
      di1State: "di1",
      frcState: "frc",
      preState: "pre",
      cpnDolState: "cpnDol",
    }),
    ...mapWritableState(useDisplayStore, {
      breakpointState: "breakpoint",
      windowHeightState: "windowHeight",
    }),
    ...mapState(useDisplayStore, [
      "isMobile",
      "showLayoutElements",
      "isXSmall",
      "isSmall",
      "isMedium",
      "isLarge",
      "isXLarge"
    ]),
    ...mapWritableState(useAuthStore, [
      "isDrawerOpened"
    ]),
    ...mapWritableState(useDisplayStore, {
      mobileState: "mobile",
      breakpointState: "breakpoint",
      windowHeightState: "windowHeight",
      drawerState: "drawer",
      isDrawerOpened: "isDrawerOpened",
      customsDrawer: "customsDrawer",
      notificationsDrawer: "notificationsDrawer",
      miniMenu: "mini",
      isMiniMenuActivated: "isMiniMenuActivated"
    }),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    routePath () {
      return this.$route.path
    },
    now () {
      return new Date()
    }
  },
  methods: {
    ...mapActions(useAuthStore, { 
      resetAuthenticatedState: "resetAuthenticated",
    }),
    ...mapActions(useTableStore, [
      "getDI1",
      "getFRC",
      "getPRE",
      "getCPNDOL",
      "setFormsData",
      "getFormsData",
    ]),
    fetchDI1 () {
      this.getDI1()
        .then((response) => {
          const data = response.data
          this.di1State = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchFRC() {
      this.getFRC()
        .then((response) => {
          const data = response.data
          this.frcState = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchPRE() {
      this.getPRE()
        .then((response) => {
          const data = response.data
          this.preState = data
          
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchCPN_DOL() {
      this.getCPNDOL()
        .then((response) => {
          const data = response.data
          this.cpnDolState = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchForms () {
      if (this.isEditing) {
        return
      }
      this.getFormsData()
        .then((response) => {
          const data = response.data.data
            this.cdi = data.CDI
            this.wdof25 = data.WDOF25
            this.wdog25 = data.WDOG25
            this.dr1 = data.DR1
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchMinutelyAndUpdate () {
      this.fetchDI1()
      this.fetchFRC()
      this.fetchPRE()
      this.fetchCPN_DOL()
      this.fetchForms()
      this.startCountdown()
      this.interval = setInterval(() => {
        this.fetchDI1()
        this.fetchFRC()
        this.fetchPRE()
        this.fetchCPN_DOL()
        this.fetchForms()
        this.updateLastUpdateState(new Date())
        this.startCountdown()
      }, 5000)
    },
    setForms () {
      this.isEditing = false
      this.setFormsData(this.cdi, this.wdof25, this.wdog25, this.dr1)
        .then(() => {
          this.snackbarSuccess = true
          this.fetchForms()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    startCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval)
      }

      let seconds = 5
      this.countdown = seconds

      this.countdownInterval = setInterval(() => {
        seconds--
        this.countdown = seconds

        if (seconds <= 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)
    },
    handleInput () {
      this.isEditing = true
      this.resetEditTimeout()
    },
    resetEditTimeout() {
      if (this.editTimeout) {
        clearTimeout(this.editTimeout)
      }
      this.editTimeout = setTimeout(() => {
        this.isEditing = false
      }, 10000)
    },
    updateLastUpdateState(value) {
      this.lastUpdateState = value
    },
    setBreakpoint (value) {
      this.breakpointState = value
    },
    updateWindowHeight () {
      this.windowHeightState = window.innerHeight
    },
    logout () {
      this.resetAuthenticatedState()
      this.$router.push("/")
    },
    changeMenuState () {
      if (this.isMobile) {
        this.drawerState = !this.drawerState 
        return
      }
      if (!this.isLarge && !this.isXLarge) {
        this.notificationsDrawer = false
        this.customsDrawer = false
      }
      this.miniMenu = !this.miniMenu
    },
  }
}
</script>

<style scoped>
@font-face {
  font-family: AvenirNextM-Regular;
  src: url("~@/assets/fonts/AvenirNextM-Regular.ttf");
}
::v-deep div.v-radio.disabled.v-radio--is-disabled.theme--dark > div > i {
  color: #303030;
}

.sync {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
  font-family: Arial, sans-serif;
  color: #00ff00;
  text-shadow: 0 0 8px #00ff00;
}

</style>
